/**
 * Mutiny
 *
 * @link https://support.mutinyhq.com/en/articles/3483177-custom-mutiny-data-integrations-javascript
 */
"use strict";

export default class Mutiny {
  constructor() {
    this.parameters = {};

    this.add_listeners();
  }

  /**
   * Check window.mutiny object and add listener
   *
   * @return void
   */
  add_listeners() {
    let _self = this;

    if ( typeof window.mutiny !== "undefined" && window.mutiny && window.mutiny.hasOwnProperty("experiences") ) {
      _self.set_parameters(window.mutiny.experiences[0]);
    }

    window.addEventListener( "mutiny:experience-impression", ( experience ) => {
      _self.set_parameters(experience.detail);
    } );
  }

  /**
   * Construct parameters object
   *
   * @param object experience
   * @return void
   */
  set_parameters(experience) {
    if (!experience) return;

    this.parameters = {
      audienceSegment: experience.audienceSegment,
      experience: experience.experience,
      impressionType: experience.impressionType,
      page: experience.page,
      variationName: experience.variationName,
      variationKey: experience.variationKey,
    };
  }

  /**
   * Get parameters
   *
   * @returns object
   */
  get_parameters() {
    return this.parameters;
  }
}
