/**
 * PostHog
 * 
 */
"use strict";

import Cookies from "js-cookie";

export default class PostHog {
  constructor() {
    this.parameters = {};
  }

  /**
   * Get parameters
   *
   * @returns object
   */
  get_parameters() {
    const phCookie = Cookies.get('_ph_bootstrapData');

    // Only fire PostHog events if the cookie exists and path matches the current path
    if ( phCookie && JSON.parse(phCookie)?.path == document.location.pathname )
    {
      let params = JSON.parse(phCookie);

      this.parameters = {
        featureFlag: params.featureFlag || '',
        variantType: params.variantType || '',
      }
    }

    return this.parameters;
  }
}
