/**
 * Americor Call Scheduler Helper
 *
 * Get and set call scheduler links
 */
"use strict";

export default class CallScheduler
{
  /**
   * Call Scheduler class
   * 
   * @var string
   */
  static call_scheduler = '.call-scheduler-link';

  /**
   * Time to wait before redirecting users to call scheduler
   * 
   * @var int
   */
  static redirect_to_scheduler_ttl = 30000;

  /**
   * Constructor
   * 
   */
  constructor()
  {
    this.add_listeners();
  }

  /**
   * Add event listeners
   *
   * @return void
   */
  add_listeners()
  {
    document.addEventListener('DOMContentLoaded', (e) => {
      this.add_callscheduler_link();
    });
  }

  /**
   * Get and set Lead callSchedulerLink
   *
   */
  add_callscheduler_link()
  {
    const lead  = sessionStorage.getItem('lead');
    const links = document.querySelectorAll( CallScheduler.call_scheduler );

    if ( links.length == 0 || ! lead ) return;

    if ( JSON.parse( lead ).callSchedulerLink )
    { 
      const callSchedulerLink = JSON.parse( lead ).callSchedulerLink;

      links.forEach( ( link ) => link.href = callSchedulerLink );
    }
  }
}