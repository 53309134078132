/**
 * UTM Helper Component
 *
 */
"use strict";

export default class TestKit {
  
  /**
   * Is Testing Active
   * 
   * @var boolean
   */
  static isActive = false;

  constructor() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    TestKit.isActive = ( urlParams.get('isTesting') && urlParams.get('isTesting') == 112233 ) ? true : false;
    
    this.init();
  }

  /**
   * 
   * @returns boolean
   */
  is_active() {
    return TestKit.isActive;
  }

  /**
   * Initialize testing panel
   *
   */
  init() {
    if ( TestKit.isActive == false ) return;

    let panel = document.createElement('div');
        panel.id = 'testingPanel';
        panel.style = 'position: fixed; bottom: 0; left: 0; width: 100%; background: #000; color: #fff; font-size: 14px; padding: 15px 10px; z-index: 10000';

    let input = document.createElement('textarea');
        input.rows = 5;
        input.style = 'width: 100%; height: 200px; background: #000; height: 200px; color: #fff; font-size: 14px; border: none; outline: none; padding: 25px 10px 0;';
        input.name = 'testingParameters';
        input.placeholder = '# TESTING MODE\r\n\r\nRequests do not submit to API. Add testing parameters as a JSON object. Open up browser console to see responses. \r\n\r\nExample: { "debtCreditReport": 5000 }';

    let button = document.createElement('a');
        button.text = 'Toggle Test Panel';
        button.style = 'position: absolute; top: 5px; left: 20px; cursor: pointer;'
        button.onclick = function() {
          if ( input.classList.contains('is-hidden') )
          {
            input.classList.remove('is-hidden');
            input.style.display = 'block';
          }
          else
          {
            input.classList.add('is-hidden');
            input.style.display = 'none';
          }
        }

    panel.appendChild(input);
    panel.appendChild(button);

    document.body.style.paddingBottom = '230px';
    document.body.appendChild(panel);
  }

  /**
   * Get testing parameters
   *
   * @return mixed
   */
  getParameters() {
    if ( TestKit.isActive == false ) return;

    return document.querySelector('textarea[name="testingParameters"]').value;
  }
}
